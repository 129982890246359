.footer {
    bottom: 0;
    background: linear-gradient(to right, #c31432, #240b36);
    padding-top: 15px;
    padding-bottom: 15px;
    color: white;
    flex-shrink: 0;
    font-size: 14px;
    width: 100%;
}

.container {
    min-width: 290px;
    max-width: 1200px;
    margin: 0 auto;
}

.footer__section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.icon {
    width: 14px;
}

.logoWhite {
    padding-bottom: 10px;
    font-size: 17px;
}

.footer__address {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.footer__address a {
    text-decoration: none;
    color: #ffffff;
}

.footer__address a:hover {
    text-decoration: none;
    color: #ffffff;
}

.contactListFooter {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-top: 10px;
}

.contactListFooter__item {
    transition: 250ms;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.contactListFooter__link:hover {
    text-decoration: wavy;
}

.contactListFooter__link {
    color: #ffffff;
    text-decoration: none;
}

.phone {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
    color: #c31432;
}

@media screen and (min-width: 768px) {
    .container {
        min-width: 768px;
        max-width: 1199px;
    }

    .bodyContainer {
        min-width: 768px;

    }

    .menuBtn {
        right: 100px;
    }

    .closeBtn {
        top: 20px;
        right: 100px;
    }
}

@media screen and (min-width: 1200px) {
    .footer {
        bottom: 0;
        background: linear-gradient(to right, #c31432, #240b36);
        padding-top: 15px;
        padding-bottom: 15px;
        color: white;
        flex-shrink: 0;
        font-size: 18px;
        width: 100%;
    }

    .container {
        min-width: 290px;

        padding: 0 15px;
        margin: 0 auto;
    }

    .footer__section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        max-width: 1200px;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .footer__box {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }

    .logoWhite {
        padding-bottom: 10px;
        font-size: 20px;
    }

    .icon {
        width: 18px;
    }

    .footer__address {
        display: flex;
        justify-content: center;
        width: 190px;
        display: flex;
        justify-content: center;
        align-items: center;
        row-gap: 10px;
    }

    .contactListFooter {
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 10px;
        padding-top: 10px;
    }

    .contactListFooter__item {
        transition: 250ms;
    }

    .contactListFooter__link:hover {
        text-decoration: wavy;
    }

    .contactListFooter__link {
        color: #ffffff;
        text-decoration: none;
    }

    .phone {
        display: inline-block;
        width: 1em;
        height: 1em;
        stroke-width: 0;
        stroke: currentColor;
        fill: currentColor;
        color: #c31432;
    }
}