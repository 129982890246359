.container {
    width: 100%;
    min-width: 290px;
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 90px;
    text-align: center;
    gap: 30px
}


.title {
    font-weight: 500;
    font-size: 35;
    text-align: 'center';
    margin: 0;
}

.paragraph {
    font-size: 20px;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 30px;
}

.image {
    width: 50%;
    border-radius: 10px;
}

@media screen and (min-width: 1200px) {
    .container {
        flex-direction: row;
    }


    .title {
        font-weight: 500;
        font-size: 35;
        text-align: 'center';
        margin: 0;
    }

    .paragraph {
        font-size: 20px;
        display: flex;
        text-align: center;
        justify-content: center;
        padding: 30px;
    }

    .image {
        width: 50%;
        border-radius: 10px;
    }
}