.galleryContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    margin: 0 auto;
    padding: 15px 15px 15px 15px;
    padding-top: 90px;
}

.galleryItem {
    position: relative;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.galleryImg {
    width: 100%;
    object-fit: cover;
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
    max-width: 310px;
    width: 290px;
    height: 250px;
}

.galleryImg:hover {
    transform: scale(1.03);
}


@media screen and (min-width: 1200px) {
    .galleryContainer {
        max-width: 1200px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;
        margin: 0 auto;
        padding: 90px 15px 15px 10px;

    }

    .galleryItem {
        position: relative;

        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
            0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    }

    .galleryImg {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
        width: 370px;
        height: 250px;
    }
}