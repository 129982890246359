.mainPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto 0;
    padding-top: 0px;
    gap: 25px;
    padding-bottom: 30px;
    max-width: 767px;
    padding-top: 90px;

}

.sliderContainer {
    max-width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .splide {
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
}


.splide .splide__track {
    padding: 0;
    
}

.splide__slide {
    padding: 0;
} */

.bannerImg {
    object-fit: cover;
    height: 25em;
    object-fit: cover;
    width: 100%;
    object-position: 50% 50%;
}

/* .slide {
    position: relative;
} */

.imgLink a {
    position: absolute;
    bottom: 10px;
    right: 5px;
    font-size: 7px;
}

.logoSlider {
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoSlider li {
    width: 100vw;
    display: flex;
    justify-content: center;
}

.logoImg {
    width: 100px;
}

.imgLink a {
    color: white;
}

@media screen and (min-width: 768px) {
    .mainPage {
        max-width: 1199px;
        gap: 15px;
        padding-top: 90px;
    }

    /* .splide {
        width: 90vw;
        display: flex;
        justify-content: center;
        align-items: center;
    } */



    .bannerImg {
        object-fit: cover;
        height: 30em;
        object-fit: cover;
        width: 100%;
        object-position: 50% 50%;
    }

    .imgLink a {
        font-size: 10px;
    }

    .logoSlider {
        width: 90vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logoImg {
        width: 100px;
    }
}

@media screen and (min-width: 1200px) {
    .mainPage {
        gap: 15px;
        padding-top: 90px;
        max-width: 1200px;
    }

    .sliderContainer {
        width: 80vw;
    }


    .bannerImg {
        height: 35em;
        object-position: 50% 50%;
    }

    .imgLink a {
        right: 10px;
    }

    .logoSlider {
        width: 80vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logoImg {
        width: 100px;
    }
}

@media screen and (min-width: 1600px) {
    .sliderContainer {
        width: 75vw;
    }
}