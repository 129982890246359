.container {
    width: 100vw;
    min-width: 320px;
    max-width: 767px;

    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;

}

.bodyContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
    min-width: 320px;
    max-width: 787px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: fixed;
    top: 0;
    z-index: 5;
    background-color: white;
}

.menuBtn {
    border: none;
    background: white;
    padding-right: 30px;
    position: absolute;
    top: 30px;
    right: 15px;
    cursor: pointer;

}

.mobileMenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    /* transform: scale(1);
    transition: transform 2500ms cubic-bezier(0.4, 0, 0.2, 1); */
    transform-origin: center center;
    animation: Scale 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mobileMenu.closed {
    animation: ScaleReverse 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mobileNavList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 40px;
    line-height: 1.17;
    letter-spacing: 0.02em;
    text-decoration: none;
    color: #240b36;

}

.mobileNavList a {
    color: #240b36;
}

.mobileNavList a:hover {
    color: #c31432;
}

.closeBtn {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    border: none;
    background-color: white;
    padding: 11px 10.33px 10.33px 11px;
    fill: black;
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
    font-size: 24px;
}

.mobileMenu .closeBtn:hover,
.mobileMenu .closeBtn:focus {
    cursor: pointer;
    fill: #c31432;
    transform: scale(1.1);
}

.navList {
    display: none;
}

.contactList {
    display: none;
}



/* --------------------------------------------------------------------------------- */
@media screen and (min-width: 768px) {
    .container {
        min-width: 768px;
        max-width: 1199px;
    }

    .bodyContainer {
        min-width: 768px;
        max-width: 1199px;
    }

    .menuBtn {
        right: 100px;
    }

    .closeBtn {
        top: 20px;
        right: 100px;
    }
}

@media screen and (min-width: 1200px) {
    .container {
        max-width: 1200px;
        padding: 0 15px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .bodyContainer {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        max-width: 1200px;
    }

    .menuBtn {
        display: none;
    }

    .header {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.19;
        top: 0;
        left: 0;
        right: 0;
    }

    .navList {
        display: flex;
        list-style: none;
        gap: 10px;
        display: flex;
        flex-grow: 2;
        margin-right: 100px;
        margin-left: 83px;
        align-items: center;
        gap: 50px;
    }

    .navList a {
        text-decoration: none;
        color: #240b36;
    }

    .navList a:hover {
        text-decoration: none;
        color: #c31432;
    }

    .contactList {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-inline-start: 0;
        flex-grow: 1;
        list-style: none;
        gap: 10px;
        padding-left: 50px;
    }

    .contactIcon {
        width: 18px;
        padding-right: 5px;
    }

    .contactIcon:hover {
        width: 14px;
        padding-right: 5px;
        fill: white;
    }

    .contactList a {
        text-decoration: none;
        color: #240b36;
    }

    .contactList a:hover {
        text-decoration: none;
        color: #c31432;
    }


    .contactList [href="tel: 18 33 19 0 66"] {
        margin-right: 10px;
        margin-left: 20px;
    }
}

@keyframes Scale {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}