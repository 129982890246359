.container {
    width: 100%;
    min-width: 310px;
    max-width: 767px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 90px;

}

.grid {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 95%;
    min-width: 310px;
    max-width: 767px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.productPhoto {
    max-width: 290px;
    height: 294px;
    width: 100%;
    object-fit: cover;
}

.imgSource {
    font-size: 9px;
    text-align: right;
    opacity: 0.7;
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #ffffff;
    color: #000000;
}

.imgSource a {
    color: #000000;
    text-decoration: none;
}

/* ............................. */

.grid__box {
    transition-property: box-shadow;
    transition-duration: 250ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.grid__box:hover {
    background: #ffffff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 4px 4px rgba(0, 0, 0, 0.06),
        1px 4px 6px rgba(0, 0, 0, 0.16);
    cursor: pointer;
}

.grid__boxCard {
    margin: 0;
    background: #ffffff;
    border-bottom: 1px solid #eeeeee;
}

.grid__card {
    position: relative;
    overflow: hidden;
    max-width: 310px;
    height: 294px;
}

.grid__box:hover .grid__textOnTop {
    transform: translateY(-100%);
}

.grid__textOnTop {
    background-color: rgba(36,
            11,
            54, 0.9);
    text-align: center;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.55;
    letter-spacing: 0.03em;
    color: #ffffff;
    transform: translateY(0);
    transition-duration: 250ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    height: 300px;
}

.grid__cardDescription {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.5;
    color: #212121;
    padding-top: 15px;
    width: 310px;
    height: 60px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

li {
    display: flex;
}

@media screen and (min-width: 768px) {
    .productPhoto {
        max-width: 310px;
    }
}

@media screen and (min-width: 1200px) {
    .container {
        min-width: 769px;
        max-width: 1200px;
    }

    .grid {
        width: 100%;
        min-width: 769px;
        max-width: 1200px;
        max-width: 1200px;
    }

    .productPhoto {
        max-width: 370px;
        height: 294px;
    }



    .grid__card {
        max-width: 370px;
        height: 294px;
    }

    .grid__textOnTop {
        padding: 15px;
        font-size: 16px;
    }

    .grid__cardDescription {
        font-size: 18px;
        margin-bottom: 5px;
        width: 370px;
    }

}