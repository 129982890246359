.container {
    width: 100%;
    min-width: 320px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-bottom: 30px;
    padding-top: 90px;

}

.map {
    width: 300px;
    height: 300px;
    border: 0;
    padding-top: 10px;

}

.addressList {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    list-style: none;
    font-size: 20px;
}

.list li {
    justify-content: center;
}

.address {
    width: 169px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    font-size: 16px;

}

.iconLocalization {
    width: 30px
}

.iconSmall {
    width: 20px;
    padding-right: 5px;
}

.contact {
    padding-top: 50px;
}

.contactList {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    list-style: none;
}

.contactList__item a {
    text-decoration: none;
    color: black;
    font-size: 16px;
}

.contactList__item:hover,
.contactList__item a:hover {
    color: #c31432;
}

.openHours {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.dayWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    max-width: 280px;
}

.dayWrapper span {
    width: 50%;
}

@media screen and (min-width: 768px) {
    .container {
        min-width: 768px;
        max-width: 1199px;
    }

    .map {
        width: 500px;
        height: 300px;
        border: 0;
    }

    .addressList {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 30px;
        list-style: none;
        font-size: 20px;
    }


    .address {
        width: 169px;
        font-size: 20px;
    }

    .contact {
        padding-top: 50px;
    }

    .contactList {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
        list-style: none;
        font-size: 20px;
    }

    .contactList__item a {
        text-decoration: none;
        color: black;
        font-size: 20px;
    }

    .contactList__item:hover,
    .contactList__item a:hover {
        /* transform: scale(0, 9); */
        color: #c31432;
    }

    .openHours {
        font-size: 20px;
    }
}

@media screen and (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }

    .containers {
        display: flex;
        padding-bottom: 30px;
    }

    .hoursContactContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .containers .addressContainer {
        width: 50%;
    }

    .containers .hoursContactContainer {
        width: 50%;
    }

    .map {
        width: 600px;
        height: 500px;
    }

    .addressList {
        gap: 90px;
    }


    .address {
        width: 200px;
    }
}