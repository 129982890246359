.logo {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    font-family: "Raleway";
    font-weight: 700;
    font-size: 30px;
    line-height: 1.16;
    letter-spacing: 0.03em;
    padding-bottom: 25px;
    padding-top: 24px;
    text-decoration: none;

    background: linear-gradient(to right, #c31432, #240b36);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.logo a {
    background:
        linear-gradient(to right, #c31432, #240b36),
        linear-gradient(to right,
            rgba(255, 0, 0, 1),
            rgba(255, 0, 180, 1),
            rgba(0, 100, 200, 1));
    background-size: 100% 3px, 0 3px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms;
}

.logo a:hover {
    background-size: 0 3px, 100% 3px;
}